var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-sidebar',{attrs:{"id":"sidebar-backdrop","backdrop":"","shadow":"","bg-variant":"white","sidebar-class":"sidebar-lg","no-header":"","right":"","visible":_vm.productAddSidebarActive},on:{"change":function (val) { return _vm.$emit('update:product-add-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Márgenes y precio ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"simpleRules"},[(_vm.show)?_c('b-form',{staticClass:"p-2",on:{"submit":_vm.onSubmit,"reset":_vm.onReset}},[_c('b-form-group',[_c('b',[_vm._v("P0001 - AGRINUTRIENTE N (LT)")])]),_c('validation-provider',{attrs:{"name":"Precio base","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Precio base","label-for":"input-base-price"}},[_c('b-form-input',{attrs:{"id":"input-base-price","placeholder":"Ingresa el precio base del producto","type":"number","required":""},model:{value:(_vm.form.basePrice),callback:function ($$v) {_vm.$set(_vm.form, "basePrice", $$v)},expression:"form.basePrice"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('p',[_vm._v("Márgenes por lista de precios")]),_c('validation-provider',{attrs:{"name":"Margen - Lista de precio 101","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Margen LP 101","label-for":"input-margin-101"}},[_c('b-form-input',{attrs:{"id":"input-margin-101","placeholder":"Ingresa el margen de la lista de precio 101","type":"number","required":""},model:{value:(_vm.form.margin101),callback:function ($$v) {_vm.$set(_vm.form, "margin101", $$v)},expression:"form.margin101"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Margen - Lista de precio 102","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Margen LP 102","label-for":"input-margin-102"}},[_c('b-form-input',{attrs:{"id":"input-margin-102","placeholder":"Ingresa el margen de la lista de precio 102","type":"number","required":""},model:{value:(_vm.form.margin102),callback:function ($$v) {_vm.$set(_vm.form, "margin102", $$v)},expression:"form.margin102"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Margen - Lista de precio 103","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Margen LP 103","label-for":"input-margin-103"}},[_c('b-form-input',{attrs:{"id":"input-margin-103","placeholder":"Ingresa el margen de la lista de precio 103","type":"number","required":""},model:{value:(_vm.form.margin103),callback:function ($$v) {_vm.$set(_vm.form, "margin103", $$v)},expression:"form.margin103"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Margen - Lista de precio 104","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Margen LP 104","label-for":"input-margin-104"}},[_c('b-form-input',{attrs:{"id":"input-margin-104","placeholder":"Ingresa el margen de la lista de precio 104","type":"number","required":""},model:{value:(_vm.form.margin104),callback:function ($$v) {_vm.$set(_vm.form, "margin104", $$v)},expression:"form.margin104"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Crear ")]),_c('b-button',{staticStyle:{"margin-left":"5px"},attrs:{"type":"reset","variant":"danger"}},[_vm._v(" Limpiar ")])],1):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }