<template>
  <div>
    <b-sidebar
      id="sidebar-backdrop"
      backdrop
      shadow
      bg-variant="white"
      sidebar-class="sidebar-lg"
      no-header
      right
      :visible="productAddSidebarActive"
      @change="(val) => $emit('update:product-add-sidebar-active', val)"
    >

      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            Márgenes y precio
          </h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <!-- Campos formulario -->
        <validation-observer ref="simpleRules">
          <b-form
            v-if="show"
            class="p-2"
            @submit="onSubmit"
            @reset="onReset"
          >
            <b-form-group>
              <b>P0001 - AGRINUTRIENTE N (LT)</b>
            </b-form-group>

            <validation-provider
              v-slot="{ errors }"
              name="Precio base"
              :rules="{ required: true }"
            >
              <b-form-group
                label="Precio base"
                label-for="input-base-price"
              >
                <b-form-input
                  id="input-base-price"
                  v-model="form.basePrice"
                  placeholder="Ingresa el precio base del producto"
                  type="number"
                  required
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- Margenes -->
            <p>Márgenes por lista de precios</p>

            <!-- LP101 -->
            <validation-provider
              v-slot="{ errors }"
              name="Margen - Lista de precio 101"
              :rules="{ required: true }"
            >
              <b-form-group
                label="Margen LP 101"
                label-for="input-margin-101"
              >
                <b-form-input
                  id="input-margin-101"
                  v-model="form.margin101"
                  placeholder="Ingresa el margen de la lista de precio 101"
                  type="number"
                  required
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- LP102 -->
            <validation-provider
              v-slot="{ errors }"
              name="Margen - Lista de precio 102"
              :rules="{ required: true }"
            >
              <b-form-group
                label="Margen LP 102"
                label-for="input-margin-102"
              >
                <b-form-input
                  id="input-margin-102"
                  v-model="form.margin102"
                  placeholder="Ingresa el margen de la lista de precio 102"
                  type="number"
                  required
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- LP103 -->
            <validation-provider
              v-slot="{ errors }"
              name="Margen - Lista de precio 103"
              :rules="{ required: true }"
            >
              <b-form-group
                label="Margen LP 103"
                label-for="input-margin-103"
              >
                <b-form-input
                  id="input-margin-103"
                  v-model="form.margin103"
                  placeholder="Ingresa el margen de la lista de precio 103"
                  type="number"
                  required
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- LP104 -->
            <validation-provider
              v-slot="{ errors }"
              name="Margen - Lista de precio 104"
              :rules="{ required: true }"
            >
              <b-form-group
                label="Margen LP 104"
                label-for="input-margin-104"
              >
                <b-form-input
                  id="input-margin-104"
                  v-model="form.margin104"
                  placeholder="Ingresa el margen de la lista de precio 104"
                  type="number"
                  required
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <b-button
              type="submit"
              variant="primary"
            >
              Crear
            </b-button>
            <b-button
              style="margin-left: 5px;"
              type="reset"
              variant="danger"
            >
              Limpiar
            </b-button>

          </b-form>
        </validation-observer>

      </template>

    </b-sidebar>
  </div>
</template>

<script>
import axios from '@axios'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@/utils/validations/validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  model: {
    prop: 'productAddSidebarActive',
    event: 'update:product-add-sidebar-active',
  },
  props: {
    productAddSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,

      form: {
        basePrice: '',
      },

      show: true,
    }
  },

  created() {
    //
  },

  methods: {
    onSubmit(event) {
      event.preventDefault()
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          axios
            .post('accounting-system/entities', this.form)
            .then(response => {
              this.makeToast('success', 'Cliente agregado', `Se ha creado el cliente ${response.data.data.nombre}.`)
              this.clearForm()
              this.$emit('update:product-add-sidebar-active', false)
              this.$emit('refreshData')
            })
            .catch(error => {
              this.showErrors(error)
            })
        } else {
          this.makeToast('danger', 'Error', 'No es posible crear el cliente.')
        }
      })
    },

    onReset(event) {
      event.preventDefault()
      this.clearForm()
    },

    clearForm() {
      // Reset our form values
      this.form.nit = ''

      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },
  },
}
</script>
